<template>
  <main>
      <h1 class="headline blue--text text-center mt-4 mb-3">Kumulatiivinen puheluraportti</h1>
      <v-row>
          <v-col>
              <v-select
                  v-model="selected_projects"
                  :items="projects_list"
                  :return-object="false"
                  item-value="id"
                  item-name="name"
                  label="Valitse projekti(t)"
                  multiple
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                          <span v-if="selected_projects.length > 1">+ {{ selected_projects.length -1 }} muuta</span>
                      </span>
                  </template>
                  <template v-slot:prepend-item>
                      <v-list-item
                          @click="toggleAll('selected_projects', 'projects_list')"
                      >
                          <v-checkbox
                              @click="toggleAll('selected_projects', 'projects_list')"
                              v-model="allSelected['selected_projects']"
                          ></v-checkbox>
                          Valitse kaikki
                      </v-list-item>
                      <v-divider></v-divider>
                  </template>
              </v-select>
          </v-col>
          <v-col>
              <v-select
                  v-model="selected_subprojects"
                  :items="subprojects_list"
                  :return-object="false"
                  item-value="id"
                  item-name="name"
                  label="Valitse aliprojekti(t)"
                  multiple
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                          <span v-if="selected_subprojects.length > 1">+ {{ selected_subprojects.length-1 }} muuta</span>
                      </span>
                  </template>
                  <template v-slot:prepend-item>
                      <v-list-item
                          @click="toggleAll('selected_subprojects', 'subprojects_list')"
                      >
                          <v-checkbox
                              @click="toggleAll('selected_subprojects', 'subprojects_list')"
                              v-model="allSelected['selected_subprojects']"
                          ></v-checkbox>
                          Valitse kaikki
                      </v-list-item>
                      <v-divider></v-divider>
                  </template>
              </v-select>
          </v-col>
          <v-col>
              <v-select
                  v-model="selected_medias"
                  :items="medias_list"
                  :return-object="false"
                  item-value="id"
                  item-name="name"
                  label="Valitse media(t)"
                  multiple
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                          <span v-if="selected_medias.length > 1">+ {{ selected_medias.length-1 }} muuta</span>
                      </span>
                  </template>
                  <template v-slot:prepend-item>
                      <v-list-item
                          @click="toggleAll('selected_medias', 'medias_list')"
                      >
                          <v-checkbox
                              @click="toggleAll('selected_medias', 'medias_list')"
                              v-model="allSelected['selected_medias']"
                          ></v-checkbox>
                          Valitse kaikki
                      </v-list-item>
                      <v-divider></v-divider>
                  </template>
              </v-select>
          </v-col>
          <v-col>
              <v-autocomplete
                  v-model="selected_number"
                  :search-input.sync="search_number"
                  :items="numbers_list"
                  :return-object="false"
                  item-value="id"
                  item-text="number"
                  label="Valitse numero"
                  clearable
              >
                  <template #item="{ item }">
                      {{ item.number }}
                  </template>
                  <template #selection="{ item }">
                      {{ item.number }}
                  </template>
              </v-autocomplete>
          </v-col>
      </v-row>
      <v-row class="grey darken-4">
          <v-col cols="2">
              <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
          <v-col cols="2">
              <v-select
                  v-model="selected_group"
                  :items="group_list"
                  :return-object="false"
                  label="Valitse järjestys"
              >
              </v-select>
          </v-col>
          <v-col cols="2">
              <v-btn
                  class="v-btn ma-3 green"
                  @click="getResource"
                  small
              >
                  HAE
              </v-btn>
          </v-col>
      </v-row>
      <template >
          <v-card v-if="resource" class="mt-6">
              <v-card-title>
                  Yhteensä
              </v-card-title>
              <v-card-text>

                  <v-text-field v-model="searchCumulative" label="Suodata nimen perusteella"></v-text-field>

                  <v-simple-table>

                      <thead>
                          <tr>
                            <th>Nimi</th>
                            <th>TK</th>
                            <th class="table-highlight-success">kpl</th>
                            <th class="table-highlight-success">Min yht.</th>
                            <th>Min ka.</th>

                            <th class="table-highlight-success">Liikevaihto</th>
                            <th>Oper.</th>
                            <th>Laskutuspalvelu</th>
                            <th>Muuttuvat k.</th>
                            <th>Oper.</th>

                            <th>Palkat</th>
                            <th>Mainos</th>
                            <th>MK</th>
                            <th>Asiakkaat</th>
                          </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(data,index) in cumulative" :key="'data'+index"
                            @click="!row_selection ? row_selection = index : row_selection = null"
                            :class="{selected_table_column: row_selection == index}"
                        >
                            <td>{{ data.name }}</td>
                            <td>{{ data.profit_turnover_factor }}</td>
                            <td class="table-highlight-success">{{ data.pcs }}</td>
                            <td class="table-highlight-success">{{ data.unit_total_min }}</td>
                            <td>{{ data.unit_average_min }}</td>
                            <td class="table-highlight-success">{{ data.turnover }}</td>
                            <td>{{ data.operator_accounting }}</td>
                            <td>{{ data.billing_service_postpaid_income }}</td>
                            <td>{{ data.variable_costs_total }}</td>
                            <td>{{ data.operator_billing }}</td>
                            <td>{{ data.salaries }}</td>
                            <td>{{ data.advertisement_cost }}</td>
                            <td>{{ data.profit_margin }}</td>
                            <td>
                                uusia:{{ data.new_customers }}<br/>
                                uniikkeja:{{ data.unique_customers }}<br/>
                                piilossa:{{ data.hidden_customers }}
                            </td>
                        </tr>
                      </tbody>
                  </v-simple-table>

                  <v-divider class="mt-6"></v-divider>
                  <h2>Yhteensä</h2>
                  <v-divider></v-divider>
                  <!-- totals -->
                  <v-simple-table class="mt-6">
                      <tbody>
                        <tr>
                            <td class="name-td">Liikevaihto</td>
                            <td> {{ resource.turnover }}€</td>
                            <td class="name-td">Keskiarvo</td>
                            <td>{{ resource.turnover_average }}€</td>
                        </tr>
                        <tr>
                            <td class="name-td">Muuttuvat kustannukset</td>
                            <td> {{ resource.variable_costs }}€</td>
                            <td class="name-td">Keskiarvo</td>
                            <td>{{ resource.variable_costs_average }}€</td>
                        </tr>
                        <tr>
                            <td class="name-td">Myyntikate</td>
                            <td> {{ resource.sales_margin }}€</td>
                            <td class="name-td">Keskiarvo</td>
                            <td>{{ resource.sales_margin_average }}€</td>
                        </tr>
                        <tr>
                            <td class="name-td">Yhteensä</td>
                            <td> {{ resource.events }}kpl</td>
                            <td class="name-td">Keskiarvo</td>
                            <td>{{ resource.events_average }}€</td>
                        </tr>
                        <tr>
                            <td class="name-td">Kestot</td>
                            <td> {{ resource.minutes }}min</td>
                            <td class="name-td">Keskiarvo</td>
                            <td>{{ resource.minutes_average }}min</td>
                        </tr>
                        <tr>
                            <td class="name-td">Asiakkaat</td>
                            <td>uusia: {{ resource.new_customers }}</td>
                            <td>uniikkeja: {{ resource.unique_customers }}</td>
                            <td>piilotettuja: {{ resource.hidden_customers }}</td>
                        </tr>
                      </tbody>
                  </v-simple-table>
              </v-card-text>
          </v-card>
      </template>
      <v-card v-if="resourceLoading" class="mt-6">
          Lataa
      </v-card>

  </main>
</template>

<script>
export default {
    name: "AudioCumulativeReport",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            projects_list: [],
            group_list: [],
            subprojects_list: [],
            medias_list: [],
            numbers_list: [],
            selected_projects: [],
            selected_subprojects: [],
            selected_medias: [],
            selected_number: "",
            search_number: "",
            selected_group: "",
            resourceLoading: false,
            resource: null,
            searchCumulative: "",
            allSelected: {
                selected_projects: false,
                selected_subprojects: false,
                selected_medias: false,
            },
            row_selection: null,
            col_selection: null,
        }
    },
    watch: {

        search_number(){
            if(this.search_number != "")
                this.axios.get('numbers?keyword='+this.search_number).then((r) => {this.numbers_list = r.data}) //get numbers_list (?keyword=05054)
        }

    },
    methods: {

        toggleAll(to, from){
            if(this.allSelected[to]){
                this[to] = []
                this.allSelected[to] = false
            }else{
                this.allSelected[to] = true
                this[to] = this[from].map(item => item.id);
            }

        },

        async getResource(){
            this.searchCumulative = ""
            this.resourceLoading = true
            this.resource = null
            await this.axios.post('reports/cumulative', {
                from: this.startDate,
                to: this.endDate,
                projects: this.selected_projects,
                sub_projects: this.selected_subprojects,
                medias: this.selected_medias,
                number_id: this.selected_number,
                group_by: this.selected_group,
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },

    },
    computed: {

        cumulative(){
            if (this.searchCumulative) {
                const searchTerm = this.searchCumulative.toLowerCase(); // Convert search term to lowercase for case-insensitive search
                return this.resource.data.filter(f => f.name.toLowerCase().includes(searchTerm));
            } else {
                return this.resource.data; // Return all data if searchCumulative is empty or falsy
            }
        }

    },
    mounted() {
        this.startDate = this.$day().startOf('month').format("DD.MM.YY")
        this.endDate = this.$day().format("DD.MM.YY")

        //prefetch lists list
        this.axios.get('reports/projects_list').then((r) => {this.projects_list = r.data}) //get projects_list
        this.axios.get('reports/groupby_list').then((r) => {this.group_list = r.data}) //get group_list
        this.axios.get('reports/medias_list').then((r) => {this.medias_list = r.data}) //get media_list
        this.axios.get('numbers').then((r) => {this.numbers_list = r.data}) //get numbers_list (?keyword=05054)
        this.axios.post('reports/subprojects_list', {projects:[]}).then((r) => {this.subprojects_list = r.data}) //get subprojects_list (projects limit results
    }
}
</script>

<style scoped>
.name-td{
    max-width: 120px !important;
}
</style>